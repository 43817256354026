import React, { Component } from "react";
import "./index.css";
import { widget } from "../../charting_library";

const qmApiBaseUrl = "https://app.quotemedia.com";
const qmApiDataUrl = `${qmApiBaseUrl}/data`;
const supportedResolutions = ["1", "3", "5", "15", "30", "60", "D"];

class TVChartContainer extends Component {
  dataFeed;
  getBarsData;
  intervals;
  isChartCreated = false;
  isChartReady = false;
  lastQuote;
  noAction = () => {};
  qmSid = "";
  requestsPending = 0;
  subscribers = {};
  symbol = "";
  theme = "Dark";
  webMasterId = "";
  widget = {};
  userId = 0;
  windowId = "";

  constructor(props) {
    super(props);
    this.handleQueryStringParameters(props);
    this.dataFeed = this.getDataFeed();
  }

  get id() {
    return `chart-${this.windowId}`;
  }

  componentDidMount() {
    if (this.qmSid) {
      this.createChart();
    }
  }

  componentDidUpdate() {
    this.handleSymbolUpdate(this.symbol);
    this.handleSidUpdate(this.qmSid);
  }

  componentWillUnmount() {
    this.intervals.forEach(clearInterval);
  }

  handleQueryStringParameters = () => {
	const search = new URLSearchParams(window.location.search);
    this.symbol = search.get("symbol");
    this.webMasterId = search.get("webMasterId");
    this.qmSid = search.get("sessionId");
    this.theme = search.get("theme");
	this.userId = search.get("userId");
	this.windowId = search.get("windowId");
  };

  render() {
    return <div id={this.id} className={"TVChartContainer"} style={{ height: "100%" }} />;
  }

  createChart = async () => {
    const widgetOptions = {
    //   autosize: true,
      charts_storage_api_version: "1.1",
      charts_storage_url: "https://trader2b-save-load-chart.azurewebsites.net",
      client_id: "trader2b.com",
      container: this.id,
      datafeed: this.dataFeed,
      debug: false,
      disabled_features: ["display_market_status",  "use_localstorage_for_settings"],
      enabled_features: ["show_animated_logo", "hide_left_toolbar_by_default"],
      custom_css_url: "/index.css",
      interval: "1T",
      library_path: "/charting_library/",
      locale: "en",
      symbol: this.symbol || "AAPL",
      theme: this.theme,
      timezone: "exchange",
      user_id: this.userId || "public_user_id",
    };


    try {
      this.widget = new widget(widgetOptions);

      this.widget.onChartReady(async () => {
        this.isChartReady = true;

        this.handleSymbolChange();
        this.handleIntervalChange();
      });

      this.isChartCreated = true;
    } catch (err) {
      console.error(err);
    }
  };

  getEnhancedChartData = async (
    symbolInfo,
    resolution,
    periodParams
  ) => {
      
    let symbol = symbolInfo.name;
  
    try {
      let url = `getEnhancedChartData.json?symbol=${symbol}&webmasterId=${this.webMasterId}&sid=${this.qmSid}`;
  
      let sinceDays = 0;
      if (resolution.includes("D")) {
        const from = new Date(periodParams.from * 1000);
        from.setDate(-180);
        url += `&freq=day&datatype=eod&start=${from
          .toISOString()
          .substring(0, 10)}`;
      } else {
        const daysPerYear = 365;
        const avgTradingDaysPerYear = 253;
        const tradingHoursPerDay = 8;
        const resolutionMinutes = parseInt(resolution, 10);
        const minutesPerHour = 60;
        const extraDays = 2;
  
        sinceDays =
          (periodParams.countBack * (daysPerYear / avgTradingDaysPerYear)) /
            (tradingHoursPerDay * (minutesPerHour / resolutionMinutes)) +
          extraDays;
  
        const today = new Date();
        today.setDate(today.getDate() - sinceDays);
        url += `&interval=${resolution}&startDateTime=${Math.floor(
          today.getTime() / 1000
        )}`;
      }
  
      let response = await fetch(`${qmApiDataUrl}/${url}`);
      return await response.json();
    } catch (e) {
      console.error("getBars() - " + e);
  
      return [];
    }
  };

  executeGetBars = async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { qmSid, webMasterId } = this;

    if (!webMasterId || !qmSid) {
      return;
    }

    try {
      const data = await this.getEnhancedChartData(symbolInfo, resolution, periodParams);

      if (!data || !data.results || !data.results.symbolcount) {
        onHistoryCallback([], { noData: true });
        return;
      }
      
      if (resolution === "D" || resolution === "1D") {
        if (data.results.history) {
          const history = data.results.history[0];
          if (!history.eoddata) {
            onHistoryCallback([], { noData: true });
            return;
          }

          this.processBarsData(history.eoddata, resolution, onHistoryCallback);
        }
      } else {
        if (data.results.intraday) {
          if (!data.results.intraday[0].interval) {
            onHistoryCallback([], { noData: true });
            return;
          }

          this.processBarsData(data.results.intraday[0].interval, resolution, onHistoryCallback);
        }
      }
    } catch (e) {
      console.error("chart/getBars() - " + e);
      onErrorCallback("" + e);
    }
  };

  getBars = async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { qmSid } = this;

    if (qmSid) {
      await this.executeGetBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback);
    } else {
      this.getBarsData.push({
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback,
      });
    }
  };

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }

    return response;
  };

  handleIntervalChange = () => {
    this.widget
      .activeChart()
      .onIntervalChanged()
      .subscribe(null, (interval) => {
      });
  };

  handleSidUpdate = async (prevQmSid) => {
    if (this.qmSid) {
      if (!this.isChartCreated) {
        await this.createChart();
      }

      this.getBarsData.forEach((data) =>
        this.executeGetBars(
          data.symbolInfo,
          data.resolution,
          data.periodParams,
          data.onHistoryCallback,
          data.onErrorCallback
        )
      );

      this.getBarsData = [];
    }
  };

  handleSymbolChange = () => {
    this.widget
      .activeChart()
      .onSymbolChanged()
      .subscribe(null, () => {
        this.lastQuote = undefined;
      });
  };

  handleSymbolUpdate = (prevSymbol) => {
    if (this.isChartReady && symbol !== prevSymbol) {
      this.widget.activeChart().setSymbol(symbol, () => { });
      this.getBarsData = [];
    }
  };

  onWidgetReady = (cb) => {
    setTimeout(
      () =>
        cb({
          supported_resolutions: supportedResolutions,
          supports_time: true,
        }),
      0
    );

    if (!this.widget) {
      return;
    }

    // this.saveObjectInterval = setInterval(() => this.widget.save((saveObject) => logSvc.log(JSON.stringify(saveObject))), 10000);
  };

  processBarsData = (data, resolution, onHistoryCallback) => {
    const quotes = data.map(
      (item) =>
      ({
        time: Date.parse(item.startdatetime ? item.startdatetime : item.date),
        low: item.low,
        high: item.high,
        open: item.open,
        close: item.close,
        volume: item.avolume ? item.avolume : item.sharevolume,
      })
    );

    if (!resolution.includes("D") && quotes.length >= 2) {
      const diff = (quotes[0].time - quotes[1].time) / (60 * 1000);

      // remove if last bar is not matching to the interval
      if (diff !== +resolution) {
        quotes.shift();
      }
    }

    const sortedQuotes = quotes.sort((a, b) => (a.time > b.time ? 1 : -1));
    const latestQuote = sortedQuotes[sortedQuotes.length - 1];
    if (!this.lastQuote || (latestQuote && this.lastQuote.time < latestQuote.time)) {
      this.lastQuote = latestQuote;
    }

    onHistoryCallback(sortedQuotes, { noData: false });
  };

  resolveSymbol = (symbolName, onResolve) => {
    const split_data = symbolName.split(/[:/]/);

    const symbolInfo = {
      name: symbolName,
      description: "",
      session: "24x7",
      timezone: "America/New_York",
      ticker: symbolName,
      data_status: "streaming",
      exchange: split_data[0],
      has_intraday: true,
      has_ticks: true,
      session_display: "",
      intraday_multipliers: ["1", "3", "5", "15", "30", "60"],
      minmov: 1,
      pricescale: 100,
      volume_precision: 8,
    };

    setTimeout(function () {
      onResolve(symbolInfo);
    }, 0);
  };

  searchSymbols = (userInput, exchange, symbolType, onResult) => {
    if (!userInput) {
      return;
    }

    const { qmSid, symbol, webMasterId } = this;

    const queryParams = {
      webmasterId: webMasterId,
      startsWith: userInput,
      exgroup: "nsd",
      sid: qmSid,
    };

    const queryString = Object.keys(queryParams)
      .map((param) => `${param}=${queryParams[param]}`)
      .join("&");
    const url = `getSymbolList.json?${queryString}`;

    fetch(`${qmApiDataUrl}/${url} `, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(this.handleErrors)
      .then(async (response) => {
        const data = await response.json();
        if (data.results.symbolcount === 0) {
          onResult([]);
          return;
        }

        const result = [];

        for (let s of data.results.lookupdata) {
          result.push({
            symbol: s.key.symbol,
            full_name: s.equityinfo.shortname,
            description: s.equityinfo.longname,
            exchange: s.key.exchange,
            type: "stock",
          });
        }

        onResult(result);
      })
      .catch(function (reason) {
        logSvc.error("chart/searchSymbols", reason);
      });
  };

  getDataFeed = () => ({
    onReady: this.onWidgetReady,
    searchSymbols: this.searchSymbols,
    resolveSymbol: this.resolveSymbol,
    getBars: this.getBars,
    subscribeBars: this.subscribeBars,
    unsubscribeBars: this.unsubscribeBars,
  });

  subscribeBars = (
    symbolInfo,
    resolution,
    onTick,
    listenerGuid,
    onResetCacheNeededCallback
  ) => {
    Object.keys(this.subscribers)
      .filter((guid) => guid !== listenerGuid)
      .forEach((guid) => delete this.subscribers[guid]);

    if (!(listenerGuid in this.subscribers)) {
      this.subscribers[listenerGuid] = {
        symbolInfo: symbolInfo,
        resolution: resolution,
        lastBar: this.lastQuote,
        listener: onTick,
      };
    }
  };

  unsubscribeBars = (listenerGuid) => {
    delete this.subscribers[listenerGuid];
  };

  updateBar = (resolution, lastBar, trade) => {
    if (resolution.includes("D")) {
      resolution = 1440; // 1 day in minutes === 1440
    } else if (resolution.includes("W")) {
      resolution = 10080; // 1 week in minutes === 10080
    }

    const coeff = resolution * 60;
    const newTime = trade.timestamp;
    const rounded = Math.floor(newTime / 1000 / coeff) * coeff;
    const lastBarSec = lastBar.time / 1000;

    const bar =
      rounded > lastBarSec
        ? {
          time: newTime,
          low: lastBar.close,
          high: lastBar.close,
          open: lastBar.close,
          close: trade.price,
          volume: trade.accumulatedVolume,
        }
        : {
          time: lastBar.time,
          low: trade.price < lastBar.low ? trade.price : lastBar.low,
          high: trade.price > lastBar.high ? trade.price : lastBar.high,
          open: lastBar.open,
          close: trade.price,
          volume: lastBar.volume + trade.size * 100,
        };

    return bar;
  };

  updateBars = async (trade) => {
    for (var listenerGuid in this.subscribers) {
      (async (_listenerGUID, _subscriptionRecord) => {
        try {
          if (_subscriptionRecord.symbolInfo.ticker === trade.symbol) {
            const isListenerSubscribed = _listenerGUID in this.subscribers;
            if (!isListenerSubscribed || !_subscriptionRecord.lastBar) {
              return;
            }

            const latestBar = this.updateBar(_subscriptionRecord.resolution, _subscriptionRecord.lastBar, trade);
            _subscriptionRecord.listener(latestBar);
            _subscriptionRecord.lastBar = latestBar;
          }
        } catch (e) {
          logSvc.log("updateBars - ", e);
        }
      })(listenerGuid, this.subscribers[listenerGuid]);
    }
  };

}

export default TVChartContainer;
