import './App.css';
import TVChartContainer from './components/TVChartContainer';

const App = () => {
	return (
		<div className={'App'} id=''>
			<TVChartContainer />
		</div>
	);
}

export default App;
